import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LightGallery } from "lightgallery/lightgallery";
import { ReplaySubject, takeUntil } from "rxjs";
import {
  Photo,
  Session,
  Student,
  Video,
} from "src/app/interfaces/landing-page";
import {
  SingleSessionPage,
  SingleSessionPageData,
} from "src/app/interfaces/single-session-page";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgVideo from "lightgallery/plugins/video";
import { InitDetail } from "lightgallery/lg-events";
import { LocalizationService } from "src/app/services/localization.service";
import { Direction } from "src/app/enums/direction";
@Component({
  selector: "app-single-session-page",
  templateUrl: "./single-session-page.component.html",
  styleUrls: ["./single-session-page.component.scss"],
})
export class SingleSessionPageComponent implements OnInit {
  singleSessionPage!: SingleSessionPage;
  constructor(
    private route: ActivatedRoute,
    private localizationService: LocalizationService
  ) {}
  singleSession!: Session;
  students!: Student[];
  photos!: Photo[];
  videos!: Video[];
  oldIdForPrimaryStage = "5716959832309760";
  oldIdForMiddleStage = "6239405559775232";
  oldIdForSecondaryStage = "6282837611249664";
  ngOnInit() {
    window.scrollTo(0, 0);
    this.localizationService
      .getLocale()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locale) => {
        this.flickityConfig.rightToLeft =
          locale.direction === Direction.RIGHT_TO_LEFT;
      });
    const page = this.route.snapshot.data["page"] as SingleSessionPageData;
    this.singleSessionPage = page.meta;
    this.singleSession = page.data.singleSession;
    this.students = page.data.students;
    this.photos = page.data.photos;
    this.videos = page.data.videos;
  }

  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    // contain: true,
    pageDots: false,
  };

  unsubscribe$ = new ReplaySubject(1);

  lightGallery!: LightGallery;
  needRefresh = false;
  settings = {
    licenseKey: "123",
    thumbnail: true,
    counter: false,
    selector: ".item",
    videojs: true,
    videojsOptions: {
      muted: true,
    },
    plugins: [lgVideo, lgThumbnail],
  };
  onInit = (detail: InitDetail): void => {
    this.lightGallery = detail.instance;
  };

  getThumbnailURL(url: string): string {
    const normalizedURL = new URL(url);
    if (
      ["www.youtube.com", "youtube.com", "youtu.be"].indexOf(
        normalizedURL.host
      ) !== -1
    ) {
      let videoId: string | undefined;
      const v = normalizedURL.searchParams.get("v");
      if (v && v.match(/^[\w-]{10,12}$/)) {
        videoId = v;
      } else {
        const match = /^(?:\/embed)?\/([\w-]{10,12})$/.exec(
          normalizedURL.pathname
        );
        if (match) {
          videoId = match[1];
        }
      }

      if (videoId) {
        return `http://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      }
    }
    return "http://img.youtube.com/vi/0/hqdefault.jpg";
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}
