<app-page>
  <page-header></page-header>
  <page-content>
    <div class="details-container">
      <div class="tournament-info">
        <h1 class="mt-2">{{ singleSession.name }}</h1>
        <p>{{ singleSession.date | date }} - {{ singleSession.location }}</p>
      </div>
      <div class="cards-container">
        <a
          href="sessions/{{ singleSession.id }}#lightgallery-photos"
          target="_self"
          class="card photos-card"
        >
          <div class="headline-text">الصور</div>
        </a>
        <a
          href="sessions/{{ singleSession.id }}#lightgallery-videos"
          target="_self"
          class="card videos-card"
        >
          <div class="headline-text">الفيديو</div>
        </a>
        <a
          [href]="singleSession.file | fileLink"
          target="_blank"
          class="card guide-card"
        >
          <div class="headline-text">نشرة الجائزة</div>
        </a>
      </div>
      <h1
        *ngIf="students.length > 0"
        class="student-mtwfwkeen mx-auto text-center mb-4 mt-5"
      >
        الطلاب المتفوقون
      </h1>
      <div *ngIf="students.length > 0" class="gallery">
        <div *ngFor="let student of students" class="student">
          <div class="student-header"></div>
          <img
            class="profile-pic"
            [src]="student.image | imageLink"
            alt="Profile Picture"
            onerror="if (this.src !== './assets/images/tafawoq-student-avatar.png') this.src = './assets/images/tafawoq-student-avatar.png'"
          />
          <img
            *ngIf="student.country"
            class="country-flag"
            [src]="student.country.image | imageLink"
            [alt]="student.country.image.title"
          />
          <span *ngIf="student.country" class="country-name text-light">{{
            student.country.name
          }}</span>
          <div class="info">
            <p class="mt-2 fw-bold">{{ student.name }}</p>
            <p *ngIf="student.stage.oldId">
              المرحلة
              {{
                student.stage.oldId === oldIdForPrimaryStage
                  ? "الابتدائية"
                  : null
              }}
              {{
                student.stage.oldId === oldIdForMiddleStage ? "المتوسطة" : null
              }}
              {{
                student.stage.oldId === oldIdForSecondaryStage
                  ? "الثانوية"
                  : null
              }}
            </p>
            <p *ngIf="!student.stage.oldId">الصف {{ student.stage.name }}</p>
            <p>مدرسة {{ student.school }} / {{ student.region }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Photos -->
    <div *ngIf="photos.length > 0" class="photos-parent position-relative">
      <h1 class="student-mtwfwkeen mx-auto text-center mt-5 mb-4">الصور</h1>
      <div id="lightgallery-photos" class="details-container"></div>
      <div id="photos-gallery" class="mt-3">
        <div class="container">
          <lightgallery [settings]="settings" [onInit]="onInit">
            <div class="row">
              <div
                *ngFor="let photo of photos; index as i"
                class="col-xl-4 col-lg-6 col-sm-6 mb-5 cursor-pointer"
              >
                <div
                  class="item"
                  [attr.data-src]="photo.image | imageLink"
                  [attr.data-sub-html]="photo.title"
                >
                  <div class="photos-gallery-card">
                    <div class="card overflow-hidden position-relative">
                      <img
                        class="img-fluid contain d-block w-100"
                        [src]="photo.image | imageLink"
                        [alt]="photo.title"
                      />
                    </div>
                    <h6
                      class="text-center text-dark fw-bold mt-3 mb-0 small ellipse-3"
                    >
                      {{ photo.title }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </lightgallery>
        </div>
      </div>
    </div>
    <!-- End Of Photos -->

    <!-- Videos -->
    <div *ngIf="videos.length > 0" class="videos-parent position-relative">
      <h1 class="student-mtwfwkeen mx-auto text-center p-0">الفيديو</h1>
      <div
        id="lightgallery-videos"
        class="details-container details-container-video"
      ></div>
      <div id="videos-gallery">
        <div class="container">
          <lightgallery [settings]="settings" [onInit]="onInit">
            <div class="row">
              <div
                *ngFor="let video of videos; index as i"
                class="col-xl-4 col-lg-6 col-sm-6 cursor-pointer"
              >
                <div
                  class="item"
                  [attr.data-src]="video.url"
                  [attr.data-sub-html]="video.title"
                >
                  <div class="videos-gallery-card">
                    <div
                      class="card overflow-hidden position-relative thumbnail-container"
                    >
                      <img
                        src="assets/images/youtube-icon.png"
                        alt="youtube-icon"
                        class="youtube-icon"
                      />
                      <img
                        class="img-fluid contain d-block w-100 thumbnail-image"
                        [src]="getThumbnailURL(video.url)"
                        [alt]="video.title"
                      />
                    </div>
                    <h6
                      class="text-center text-dark fw-bold mb-0 small ellipse-3"
                    >
                      {{ video.title }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </lightgallery>
        </div>
      </div>
    </div>

    <!-- End Of Videos -->
  </page-content>
  <page-footer></page-footer>
</app-page>
