import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReplaySubject, takeUntil } from "rxjs";
import { Direction } from "src/app/enums/direction";
import { HomePage, LandingPageData } from "src/app/interfaces/landing-page";
import { LocalizationService } from "src/app/services/localization.service";

@Component({
  selector: "app-landing-intro",
  templateUrl: "./landing-intro.component.html",
  styleUrls: ["./landing-intro.component.scss"],
})
export class LandingIntroComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private localizationService: LocalizationService
  ) {}

  homePageIntro!: HomePage;
  unsubscribe$ = new ReplaySubject(1);
  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    contain: true,
    groupCells: true,
    pageDots: false,
    prevNextButtons:false
  };

  slidersImages = [
    "assets/images/banner2.jpg",
  ];

  ngOnInit(): void {
    const { meta } = this.route.snapshot.data["page"] as LandingPageData;
    this.homePageIntro = meta;

    this.localizationService
      .getLocale()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locale) => {
        this.flickityConfig.rightToLeft =
          locale.direction === Direction.RIGHT_TO_LEFT;
      });
  }

  showHiddenText = false;

  toggleHiddenText() {
    this.showHiddenText = !this.showHiddenText;
    const showMoreLink = document.querySelector(".show-more");
    const hiddenText = document.querySelector(".hidden-text");
    if (hiddenText) hiddenText.classList.toggle("less");
    if (showMoreLink) showMoreLink.classList.toggle("less");
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}
