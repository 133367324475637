import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-partners',
  templateUrl: './landing-partners.component.html',
  styleUrls: ['./landing-partners.component.scss']
})
export class LandingPartnersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
