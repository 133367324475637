<div class="my-4 pt-2 position-relative">
  <div id="prize-about"></div>
  <div class="container">
    <div class="text-center">
      <h6 class="fs-1 fw-bold mt-4 mb-5">عن الجائزة</h6>
    </div>

    <div
      class="d-flex flex-column justify-content-center align-items-center gap-5"
    >
      <ul
        class="nav nav-pills mx-auto justify-content-center align-items-center"
        role="tablist"
      >
        <li
          *ngFor="let awardAboutElement of awardAboutArray; index as i"
          class="nav-item mx-2"
          role="presentation"
        >
          <button
            class="nav-link px-4"
            [ngClass]="{ active: i === 0 }"
            id="tab{{ i }}-tab"
            data-bs-toggle="tab"
            attr.data-bs-target="#tab{{ i }}-tab-pane"
            type="button"
            role="tab"
            attr.aria-controls="tab{{ i }}-tab-pane"
            aria-selected="false"
          >
            {{ awardAboutElement.title }}
          </button>
        </li>
      </ul>

      <div class="tab-content card" style="max-width: 90%; min-width: 90%">
        <div
          *ngFor="let awardAboutElement of awardAboutArray; index as i"
          class="tab-pane fade show card-body p-5"
          [ngClass]="{ active: i === 0 }"
          id="tab{{ i }}-tab-pane"
          attr.role="tabpane{{ i }}"
          attr.aria-labelledby="tab{{ i }}-tab"
          tabindex="0"
        >
          <div class="">
            <p
              [innerHTML]="awardAboutElement.content"
              class="regulation-content"
            ></p>
          </div>
        </div>
        <div
          *ngFor="let awardAboutElement of awardAboutArray; index as i"
          class="tab-pane fade"
          id="tab{{ i + 1 }}-tab-pane"
          role="tabpanel"
          attr.aria-labelledby="tab{{ i + 1 }}-tab"
          tabindex="0"
        >
          ...
        </div>
      </div>
    </div>
  </div>
</div>
