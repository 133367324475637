import { gql } from "apollo-angular";
import { Photo, Session, Student, Video } from "../interfaces/landing-page";
import { SessionPage } from "../interfaces/sessions-page";
import { SingleSessionPage } from "../interfaces/single-session-page";

export const GET_SESSION_PAGE = gql<
  {
    page: SessionPage;
    sessions: Session[];
    pagingSession: number;
  },
  { sessionLimit: number }
>`
  query ($sessionLimit: Int!) {
    page: sessions_page {
      id
      title
      image {
        id
        title
        filename: filename_download
      }
    }

    sessions(
      limit: $sessionLimit
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
    ) {
      id
      name
      location
      date
      image {
        id
        filename: filename_download
        title
      }
      active
    }

    pagingSession: sessions_aggregated(
      filter: { _and: [{ status: { _eq: "published" } }] }
    ) {
      count {
        id
      }
    }
  }
`;

export const LIST_SESSIONS_BY_PAGE = gql<
  {
    sessions: Session[];
    pagingSession: number;
  },
  { sessionLimit: number; page: number }
>`
  query ($sessionLimit: Int!, $page: Int!) {
    sessions(
      limit: $sessionLimit
      filter: { status: { _eq: "published" } }
      page: $page
    ) {
      id
      name
      location
      date
      image {
        id
        filename: filename_download
        title
      }
      active
    }
    pagingSession: sessions_aggregated(
      filter: { _and: [{ status: { _eq: "published" } }] }
    ) {
      count {
        id
      }
    }
  }
`;

export const GET_SINGLE_SESSION_PAGE = gql<
  {
    page: SingleSessionPage;
    singleSession: Session;
    students: Student[];
    photos: Photo[];
    videos: Video[];
  },
  { singleSessionId: number; singleSessionIdString: string }
>`
  query ($singleSessionId: ID!, $singleSessionIdString: String!) {
    page: sessions_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    singleSession: sessions_by_id(id: $singleSessionId) {
      id
      name
      location
      date
      image {
        id
        filename: filename_download
        title
      }
      active
      file {
        id
        filename: filename_download
        title
        type
      }
    }

    students(
      filter: {
        _and: [
          { session_id: { id: { _eq: $singleSessionIdString } } }
          { status: { _eq: "4" } }
        ]
      }
      sort: ["-date_created"]
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      country: country_id {
        name
        image {
          id
          filename: filename_download
          title
        }
      }
      school
      region
      certificate {
        id
        filename: filename_download
        title
      }
      passport {
        id
        filename: filename_download
        title
      }
      stage: stage_id {
        name
        oldId: old_id
      }
    }

    photos(
      filter: {
        _and: [
          { session_id: { id: { _eq: $singleSessionIdString } } }
          { status: { _eq: "published" } }
        ]
      }
      sort: ["-date_created"]
    ) {
      id
      title
      image {
        id
        filename: filename_download
        title
      }
    }

    videos(
      filter: {
        _and: [
          { session_id: { id: { _eq: $singleSessionIdString } } }
          { status: { _eq: "published" } }
        ]
      }
      sort: ["-date_created"]
    ) {
      id
      title
      url
    }
  }
`;
