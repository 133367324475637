import { gql } from "apollo-angular";
import { HomePage, AwardAbout, Session } from "../interfaces/landing-page";

export const GET_LANDING_PAGE_DATA = gql<
  {
    page: HomePage;
    awardAbout: AwardAbout;
    sessions: Session[];
  },
  void
>`
  query {
    page: home_page {
      title
      intro
      introductionCont: introduction_cont
      image {
        id
        filename: filename_download
        title
      }
    }
    awardAbout: about_page {
      about
      goals
      organization
      value
      conditions
      rules
      prizeRulesImage: prize_rules_image {
        id
        filename: filename_download
        title
      }
      prizeConditionsImage: prize_conditions_image {
        id
        filename: filename_download
        title
      }
    }

    sessions(
      filter: { status: { _eq: "published" } }
      limit: 5
    ) {
      id
      name
      location
      date
      image {
        id
        filename: filename_download
        title
      }
      active
      file {
        id
        filename: filename_download
        title
        type
      }
    }
  }
`;
