import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LandingPageData } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-about-us",
  templateUrl: "./landing-about-us.component.html",
  styleUrls: ["./landing-about-us.component.scss"],
})
export class LandingAboutUsComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  awardAboutArray!: { title: string; content: string }[];
  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as LandingPageData;
    const awardAbout = page.data.awardAbout;

    this.awardAboutArray = [
      { title: "عن الجائزة", content: awardAbout.about },
      { title: "من أهداف الجائزة", content: awardAbout.goals },
      { title: "تنظيم الجائزة", content: awardAbout.organization },
      { title: "الجوائز المقدمة", content: awardAbout.value },
    ];
  }
}
