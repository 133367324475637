import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ReplaySubject, takeUntil } from "rxjs";
import { Direction } from "src/app/enums/direction";
import { LandingPageData, Session } from "src/app/interfaces/landing-page";
import { LocalizationService } from "src/app/services/localization.service";

@Component({
  selector: "app-landing-sessions",
  templateUrl: "./landing-sessions.component.html",
  styleUrls: ["./landing-sessions.component.scss"],
})
export class LandingSessionsComponent implements OnInit {
  sessions!: Session[];
  flickityConfig: Flickity.Options = {
    rightToLeft: true,
    cellAlign: "right",
    wrapAround: true,
    pageDots: false,
    autoPlay: true,
  };

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private localizationService: LocalizationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as LandingPageData;
    this.sessions = page.data.sessions;
    this.localizationService
      .getLocale()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locale) => {
        this.flickityConfig.rightToLeft =
          locale.direction === Direction.RIGHT_TO_LEFT;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  goToSessionDetails(singleSession: Session) {
    this.router.navigate([`/sessions/${singleSession.id}`]);
  }
}