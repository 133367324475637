<app-page>
  <page-header></page-header>
  <page-content>
    <div id="news" class="my-5">
      <div class="container">
        <div class="row">
          <div
            *ngFor="let singleSession of sessions"
            class="col-xl-4 col-md-6 mb-4"
          >
            <a
              routerLink="/sessions/{{ singleSession.id }}"
              class="cursor-pointer"
            >
              <div class="single-news">
                <div class="img-wrapper">
                  <img
                    [src]="singleSession.image | imageLink"
                    class="img-fluid rounded-2 cover d-block mb-3 news-image hover-shadow"
                    style="aspect-ratio: 1; height: 350px"
                    [alt]="singleSession.image.title"
                  />
                </div>
                <p class="small text-light-gray">
                  {{ singleSession.date | date }}
                </p>

                <p class="fw-bold text-dark text-center">
                  {{ singleSession.name }}
                </p>
              </div>
            </a>
          </div>
        </div>

        <div class="text-center">
          <nav *ngIf="paginationButtonsNumber > 0">
            <ul class="pagination justify-content-center dark">
              <li
                *ngFor="
                  let item of [].constructor(paginationButtonsNumber);
                  index as i
                "
                (click)="showItemsByPage(i + 1)"
                class="page-item cursor-pointer"
                [ngClass]="{ active: page === i }"
              >
                <a class="page-link">{{ i + 1 }}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div *ngIf="sessions?.length === 0" class="no-data">لا توجد فعاليات</div>
  </page-content>
  <page-footer></page-footer>
</app-page>
