<section id="membership" class="bg-primary bg-opacity-10 position-relative">
  <div id="prize-membership"></div>
  <div class="container">
    <h2 class="mb-4">
      <span
        class="text-gradient subtitle h2 fw-bold"
        style="--gc-1: #00a651; --gc-2: #c29128"
      >
        شروط وضوابط الجائزة
      </span>
    </h2>

    <div class="row">
      <div
        class="col-md-6 d-flex flex-column justify-content-center mb-4 mb-md-5"
      >
        <h6 class="h4 fw-bold mb-4">شروط الترشيح</h6>
        <div [innerHTML]="awardAbout.conditions"></div>
      </div>
      <div class="col-md-6 mb-4 mb-md-5">
        <div
          class="img-gradient-wrapper"
          style="--gc-1: #00a651; --gc-2: #c29128"
        >
          <img
            [src]="awardAbout.prizeConditionsImage | imageLink"
            class="rounded-pill cover w-100"
            [alt]="awardAbout.prizeConditionsImage.title"
          />
        </div>
      </div>
      <div class="col-md-6 mb-4 mb-md-5">
        <div
          class="img-gradient-wrapper"
          style="--gc-1: #00a651; --gc-2: #c29128"
        >
          <img
            [src]="awardAbout.prizeRulesImage | imageLink"
            class="rounded-pill cover w-100"
            [alt]="awardAbout.prizeRulesImage.title"
          />
        </div>
      </div>
      <div
        class="col-md-6 d-flex flex-column justify-content-center mb-4 mb-md-5"
      >
        <h6 class="h4 fw-bold mb-4">ضوابط الترشيح</h6>

        <div [innerHTML]="awardAbout.rules"></div>
      </div>
    </div>
  </div>
</section>
