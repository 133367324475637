import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AwardAbout, LandingPageData } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-schools-membership",
  templateUrl: "./landing-schools-membership.component.html",
  styleUrls: ["./landing-schools-membership.component.scss"],
})
export class LandingSchoolsMembershipComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  awardAbout!: AwardAbout;
  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as LandingPageData;
    this.awardAbout = page.data.awardAbout;
  }
}
