import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReplaySubject, takeUntil } from "rxjs";
import { Session } from "src/app/interfaces/landing-page";
import { SessionPage, SessionPageData } from "src/app/interfaces/sessions-page";
import { CMSService } from "src/app/services/cms.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-sessions-page",
  templateUrl: "./sessions-page.component.html",
  styleUrls: ["./sessions-page.component.scss"],
})
export class SessionsPageComponent implements OnInit {
  paginationButtonsNumber!: number;
  page = 0;
  totalDocuments!: number;
  loading!: boolean;
  sessionPage!: SessionPage;
  sessions!: Session[];
  unsubscribe$ = new ReplaySubject(1);
  sessionLimit = environment.limit.sessionLimit;
  constructor(private route: ActivatedRoute, private cmsService: CMSService) {}

  ngOnInit() {
    const page = this.route.snapshot.data["page"] as SessionPageData;
    this.sessionPage = page.meta;
    const sessions = page.data.sessions;
    this.sessions = [...sessions];
    this.totalDocuments = page.data.pagingSession[0].count.id;
    this.paginationButtonsNumber = Math.ceil(
      this.totalDocuments / this.sessionLimit
    );
  }

  showItemsByPage(page: number) {
    const listItems = document.getElementsByClassName("page-item");
    for (let i = 0; i < listItems.length; i++) {
      const item = listItems[i];
      if (page === i + 1) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    }
    this.cmsService
      .getActivitiesByPage(page)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result: any) => {
          this.totalDocuments = result.pagingSession[0].count.id;
          this.sessions = [...result.sessions];
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
