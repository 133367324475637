import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LandingPageComponent } from "./components/pages/landing-page/landing-page.component";
import { SessionsPageComponent } from "./components/pages/sessions-page/sessions-page.component";
import { SingleSessionPageComponent } from "./components/pages/sessions-page/single-session-page/single-session-page.component";
import { ContactUsComponent } from "./components/pages/contact-us/contact-us.component";
import { LandingPageResolver } from "./resolvers/landing-page.resolver";
import { NewsPageResolver } from "./resolvers/sessions-page.resolver";
import { SingleNewsPageResolver } from "./resolvers/single-sessions-page.resolver";

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    resolve: { page: LandingPageResolver },
  },

  {
    path: "sessions",
    component: SessionsPageComponent,
    resolve: { page: NewsPageResolver },
  },
  {
    path: "sessions/:singleSessionId",
    component: SingleSessionPageComponent,
    resolve: { page: SingleNewsPageResolver },
  },
  // { path: "contact-us", component: ContactUsComponent },

  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
