<div class="partners-section text-center mt-5 mb-5">
  <h2 class="mb-4">
    <span
      class="text-gradient subtitle h2 fw-bold"
      style="--gc-1: #00a651; --gc-2: #c29128; line-height: 3rem"
    >
      برعاية
    </span>
  </h2>

  <a
    href="https://www.almarai.com/ar"
    target="_blank"
    class="partners-image cursor-pointer"
  >
    <img src="assets/images/almarai.svg" alt="almarai_logo" />
  </a>
</div>
