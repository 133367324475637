<app-navbar></app-navbar>
<div id="home">
  <div class="background-container">
    <img src="assets/images/prizebg.jpg" alt="" class="background-image" />
  </div>
  <div class="home-content">
    <div id="goaie-home">
      <app-landing-intro></app-landing-intro>
    </div>
    <app-landing-about-us></app-landing-about-us>
    <app-landing-sessions></app-landing-sessions>
    <app-landing-schools-membership></app-landing-schools-membership>
    <app-landing-partners></app-landing-partners>
    <app-footer-links></app-footer-links>
    <app-copyright></app-copyright>
  </div>
</div>
