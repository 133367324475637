import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { filter, fromEvent, ReplaySubject, takeUntil } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { CMSService } from "src/app/services/cms.service";
import { Dropdown, Offcanvas } from "bootstrap";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { LocalizationService } from "src/app/services/localization.service";
import { Direction } from "src/app/enums/direction";
import { environment } from "src/environments/environment";

enum MenuItemType {
  LINK,
  GROUP,
  FRAGMENT,
}

enum MenuArrowPosition {
  START,
  END,
}

interface MenuItem {
  title: string;
  type: MenuItemType;
  href?: boolean;
  link?: string;
  children?: MenuItem[];
  arrowPosition?: MenuArrowPosition;
}

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  Direction = Direction;
  MenuItemType = MenuItemType;
  MenuArrowPosition = MenuArrowPosition;
  cmsAdminURL = environment.cmsAdminURL;
  portalURI = environment.portalURI;
  navbarLinks = [
    {
      title: "عن الجائزة",
      href: "#prize-about",
      selfLink: true,
    },
    {
      title: "شروط وضوابط الجائزة",
      href: "#prize-membership",
      selfLink: true,
    },
    {
      title: "التسجيل",
      href: this.cmsAdminURL,
      external: true,
    },
    {
      title: "الدورات السابقة",
      href: "#news-and-events",
      selfLink: true,
    },
    {
      title: "بوابة المكتب",
      href: `${environment.portalURI}`,
      external: true,
    },
  ];

  @Input() landing!: boolean;

  menu: MenuItem[] = [
    { title: "الرئيسة", type: MenuItemType.LINK, href: false, link: "/" },
  ];

  @ViewChild("searchForm") searchForm!: ElementRef<HTMLFormElement>;
  @ViewChild("offcanvas") offcanvasRef!: ElementRef<HTMLDivElement>;
  @ViewChildren("offcanvasMenu")
  offcanvasMenus!: QueryList<ElementRef<HTMLElement>>;

  direction = Direction.RIGHT_TO_LEFT;

  isXXLarge!: boolean;
  heightFlag = true;

  searchKeyword = "";
  showSearchInput!: boolean;

  loading!: boolean;

  unsubscribe$ = new ReplaySubject(1);
  currentRoute;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private localizationService: LocalizationService,
    private cmsService: CMSService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.clearOffcanvasStyles();
  }

  ngOnInit(): void {
    this.currentRoute = this.route.snapshot.routeConfig?.path || undefined;
    this.breakpointObserver
      .observe(["(min-width: 1400px)"])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.isXXLarge = value.breakpoints["(min-width: 1400px)"];
      });

    fromEvent(window, "scroll")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.heightFlag = this.document.documentElement.scrollTop <= 200;
      });

    this.subscribeToLocalizationChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: PointerEvent) {
    this.handleSearchFormOnDocumentClick(event);
    this.handleOffcanvasOnDocumentClick(event);
  }

  handleSearchFormOnDocumentClick(event: PointerEvent) {
    const target = event.target as HTMLElement | null;
    if (!target || !this.searchForm || !this.searchForm.nativeElement) return;
    if (!this.searchForm.nativeElement.contains(target)) {
      this.showSearchInput = false;
    }
  }

  handleOffcanvasOnDocumentClick(event: PointerEvent) {
    const target = event.target as HTMLElement | null;
    if (
      !target ||
      !target.parentElement ||
      !this.offcanvasMenus ||
      !this.offcanvasMenus.length
    ) {
      return;
    }

    this.offcanvasMenus.forEach((elementRef) => {
      if (!target.parentElement) return;
      const element = elementRef.nativeElement;
      if (!element.contains(target)) {
        const dropdownTrigger = element.querySelector(".dropdown-toggle");
        if (!dropdownTrigger) return;
        const dropdownRef = Dropdown.getOrCreateInstance(dropdownTrigger);
        if (dropdownRef) {
          dropdownRef.hide();
        }
      }
    });
  }

  subscribeToLocalizationChange() {
    this.localizationService
      .getLocale()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locale) => {
        this.direction = locale.direction;
      });
  }

  clearOffcanvasStyles() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.document.body.removeAttribute("style");
      });
  }

  onMouseOver(trigger: HTMLAnchorElement, menu: HTMLDivElement) {
    if (!trigger || !menu) return;
    const dropdownRef = Dropdown.getOrCreateInstance(trigger);
    if (dropdownRef) {
      dropdownRef.show();
    }
  }

  onMouseLeave(trigger: HTMLAnchorElement, menu: HTMLDivElement) {
    if (!trigger || !menu) return;
    const dropdownRef = Dropdown.getOrCreateInstance(trigger);
    if (dropdownRef) {
      dropdownRef.hide();
    }
  }

  dismissOffcanvas() {
    if (!this.offcanvasRef || !this.offcanvasRef.nativeElement) return;
    const offcanvas = Offcanvas.getOrCreateInstance(
      this.offcanvasRef.nativeElement
    );
    if (offcanvas) {
      offcanvas.hide();
    }
  }

  onSearch() {
    // TODO
    if (this.searchKeyword) {
      this.searchKeyword = "";
      this.showSearchInput = false;
    }
  }
}
